import React, { useReducer } from 'react'
import { navigate } from 'gatsby'

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  projects: [],
  projectSlugs: {
    current: '',
    history: [],
  },
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PROJECTS': {
      return {
        ...state,
        projects: action.data,
      }
    }
    case 'SET_PROJECT_SLUGS': {
      const stateCopy = { ...state }

      stateCopy.projectSlugs.history = action.data

      if (state.projectSlugs.current) {
        const idx = stateCopy.projectSlugs.history.findIndex(p => p.includes(state.projectSlugs.current))
        stateCopy.projectSlugs.history.splice(idx, 1)
      }
      return {
        ...stateCopy,
      }
    }
    case 'SET_CURRENT_PROJECT': {
      console.log('inside set current project case')

      const stateCopy = { ...state }
      stateCopy.projectSlugs.current = action.currentProject

      return {
        ...stateCopy,
      }
    }
    case 'GO_NEXT_PROJECT': {
      console.log('inside next project case')
      const stateCopy = { ...state }
      // Get next project
      const newCurrent = stateCopy.projectSlugs.history.pop()

      if (stateCopy.projectSlugs.current) {
        // Get the previous project
        const previousProject = stateCopy.projectSlugs.current

        // Make the last project the previous
        stateCopy.projectSlugs.history.unshift(previousProject)
      }

      // Set a new current project
      stateCopy.projectSlugs.current = newCurrent

      navigate(newCurrent)

      return {
        ...stateCopy,
      }
    }
    case 'GO_PREV_PROJECT': {
      console.log('inside next project case')
      const stateCopy = { ...state }

      // Get next project
      const newCurrent = stateCopy.projectSlugs.history.shift()

      if (stateCopy.projectSlugs.current) {
        // Get the previous project
        const previousProject = stateCopy.projectSlugs.current

        // Make the last project the previous
        stateCopy.projectSlugs.history.push(previousProject)
      }

      // Set a new current project
      stateCopy.projectSlugs.current = newCurrent

      navigate(newCurrent)

      return {
        ...stateCopy,
      }
    }
    default:
      throw new Error('Bad action type')
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

GlobalContextProvider.propTypes = {}

export default GlobalContextProvider
